﻿<div class="route-editor-parent" bind:this={component}>
    <div class="title-parent">
        <p class="control-title">Routebeheer</p>
    </div>
    <div class="scroll-parent">
        <List accordionList dividers>
            {#each sortedRoutes as route}
                <ListItem
                        accordionItem
                        title={route.Name}
                        class="{selectedRoute?.Id === route.Id && selectedGate === null ? 'selected-accordion-item selected-route' : ''}"
                        style="--f7-list-chevron-icon-color: {selectedRoute?.Id === route.Id && selectedGate === null ? 'white' : 'black'}"
                        accordionItemOpened={selectedRoute?.Id === route.Id || openRouteIds.get(route.Id)}
                        on:accordionBeforeOpen={(ev) => preventOpening(ev)}
                        on:accordionBeforeClose={(ev) => preventOpening(ev)}
                        on:click={e => handleRouteClick(e, route)}
                        >
                    
                    <Icon f7="circle_fill"
                          slot="media"
                          class="route-color-icon"
                          style={`color: ${routeColors[route.Id]};`}/>

                    <div slot="after-title" class="accordion-button-container">
                        {#if selectedRoute?.Id === route.Id && selectedGate === null}
                            <Button
                                    iconMaterial="Edit"
                                    iconColor="white"
                                    iconSize="18px"
                                    class="accordion-item-button"
                                    on:click={() => { dispatch("editRouteName"); }}/>
                            <Button
                                    iconMaterial="Delete"
                                    iconColor="white"
                                    iconSize="18px"
                                    class="accordion-item-button"
                                    on:click={() => { dispatch("deleteRoute") }}/>
                        {/if}
                    </div>

                    <AccordionContent>
                        {#if drawGateLists}
                        <List
                                on:sortableSort={e => handleGateDrag(e, route)}
                                sortable
                                sortableEnabled
                                sortableOpposite
                                dividers
                                class="accordion-content-list">
                            {#each routeGates[route.Id] as gate}
                                <ListItem
                                        style="--f7-sortable-handler-color: {selectedGate === gate ? 'white' : 'black'}"
                                        class="{selectedGate?.Id === gate.Id ? 'selected-list-item selected-gate' : ''} clickable"
                                        on:click={e => handleGateClick(route, gate)}>
                                    <div slot="after-title" class="accordion-button-container">
                                        {#if selectedRoute?.Id === route.Id && selectedGate?.Id === gate.Id}
                                            <!-- <Button
                                                    iconMaterial="Edit"
                                                    iconColor="white"
                                                    iconSize="18px"
                                                    class="accordion-item-button"
                                                    on:click={() => { dispatch("editGateName"); }}/> -->
                                            <Button
                                                    iconMaterial="my_location"
                                                    iconColor="white"
                                                    iconSize="18px"
                                                    class="accordion-item-button"
                                                    on:click={() => { dispatch("editGateLocation"); }}/>
                                            <Button
                                                    iconMaterial="Delete"
                                                    iconColor="white"
                                                    iconSize="18px"
                                                    class="accordion-item-button"
                                                    on:click={() => { dispatch("deleteGate"); }}/>
                                        {/if}
                                    </div>
                                    <div slot="title" 
                                    on:mouseenter={() => highlightGate(gate)}
                                    on:mouseleave={() => unhighlightGate()}
                                    >{gate.Name}</div>
                                </ListItem>
                            {/each}
                        </List>
                        {/if}
                    </AccordionContent>
                </ListItem>
            {/each}
        </List>
    </div>
    <div class="button-parent">
        <Button class="secondary-button" disabled={isSaving || isAddingGate} on:click={() => dispatch('addRoute')}>
            <Icon material="Add" size="18px"/>
            Route
        </Button>
        <Button class="secondary-button" disabled={isSaving || isAddingGate || (!selectedRoute) && (!selectedGate)} on:click={() => dispatch('addGate')}>
            <Icon material="Add" size="18px"/>
            Gate
        </Button>
        <Button class="save-button" fill on:click={() => dispatch('save')} disabled="{isSaving || isAddingGate || !hasChanges}">
            {#if isSaving}
                <Preloader color="white" size="{24}" class="saving-preloader" />
            {/if}
            Opslaan
        </Button>
    </div>
</div>

<script lang="ts">
    import {AccordionContent, Button, Icon, List, ListItem, Preloader, f7} from "framework7-svelte";
    import type {IGate, IRoute} from "../../../../lib/webapi/apitypes";
    import {getLegendColorRGB, getLegendColorString} from "../../utils/style";
    import {createEventDispatcher} from "svelte";
    import { tick } from 'svelte';

    const dispatch = createEventDispatcher<{save: void, changed: void, close: void, addRoute: void, addGate: void, editRouteName: void, editGateName: void, deleteRoute: void, deleteGate: void, editGateLocation: void}>();

    export let routes: IRoute[];
    export let gates: IGate[];

    let component: HTMLElement;

    $: sortedRoutes = routes.toSorted((a, b) => a.Name.localeCompare(b.Name));

    let drawGateLists = true;
    export let isSaving = false;
    export let isAddingGate = false;

    export let hasChanges = false;

    export let selectedRoute: IRoute | null;
    export let selectedGate: IGate | null;

    export let highlightedGate: IGate | null;
    let highlightedResetTimer;

    let openRouteIds: Map<string, boolean> = new Map();

    $: routeColors = Object.fromEntries(routes
        .map((route, index) => [route.Id, getLegendColorString(getLegendColorRGB(index))]));

    $: routeGates = Object.fromEntries(routes
        .map(route => [route.Id, route.Gates.map(gateId => gates.find(gate => gate.Id === gateId))]));

    $: bringSelectedRouteAndGateIntoFocus(selectedRoute, selectedGate);

    async function handleGateDrag(event: CustomEvent, route: IRoute) {
        const item = route.Gates.splice(event.detail[0].from, 1)[0];
        route.Gates.splice(event.detail[0].to, 0, item);
        markChanged();
        await refreshGateLists();
    }
    
    async function bringSelectedRouteAndGateIntoFocus(selectedRoute: IRoute, selectedGate: IGate) {
        await tick();
        if (selectedGate) {
            const g = component.querySelector('.selected-gate');
            g?.scrollIntoView({behavior: 'smooth'});
        } else
        if (selectedRoute) {
            const r = component.querySelector('.selected-route');
            r?.scrollIntoView({behavior: 'smooth'});
        }
    }

    function handleRouteClick(event: CustomEvent | undefined, route: IRoute) {
        const detail = event.detail as CustomEvent;
        const fromButton = (detail.target as HTMLElement)?.closest('.accordion-item-button');
        if (fromButton) {
            return;
        }

        const routeOpen = openRouteIds.get(route.Id);
        if (routeOpen || (route.Id === selectedRoute?.Id && !selectedGate)) {
            openRouteIds.delete(route.Id);
            openRouteIds = openRouteIds;
            if (route.Id === selectedRoute?.Id) {
                selectedRoute = null;
            }
        } else {
            selectedRoute = route;
            openRouteIds.set(route.Id, true);
        }
        selectedGate = null;
    }
    
    function handleGateClick(route: IRoute, gate: IGate){
        selectedRoute = route;
        selectedGate = gate;
    }
    

    function markChanged() {
        dispatch('changed');
    }
    
    function highlightGate(gate: IGate) {
        // We use a timer so that when the user has a gate selected, and the mouse goes out of the current
        // highlighted gate and he is underway to another highlighted gate, the map does not jump
        // back to the selected gate in between.

        if (highlightedResetTimer) {
            clearTimeout(highlightedResetTimer);
            highlightedResetTimer = undefined;
        }
        highlightedGate = gate;
    }

    function unhighlightGate() {
        highlightedResetTimer = setTimeout(() => {
            highlightedGate = null;
        }, 1500);
    }

    /**
     * Since we're updating the list state order, the list items don't point to the correct gate anymore.
     * This function forces the list to redraw, so the correct gate is shown.
     */
    async function refreshGateLists(){
        gates = gates;
        routes = routes;

        drawGateLists = false;
        await tick();
        drawGateLists = true;
    }

    function preventOpening(ev: CustomEvent) {
        // ev.detail[0] is a prevent function that, when invoked, which is what we do, 
        // prevents opening of the accordion. We want to prevent that because we use handleRouteClick for this.
        ev.detail[0]();
    }

</script>

<style lang="scss">
  @import "../../assets/scss/colors";

  .title-parent {
    height: 56px;
    padding: 8px;
  }

  .scroll-parent {
    overflow: scroll;
    flex-grow: 1;
    height: 100%;
  }

  .button-parent {
    background: #{$surface-container};
    height: 80px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px;
    margin: -12px -12px 0 -12px;
    align-items: center;
  }

  .route-editor-parent {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .control-title {
    font-size: 14pt;
    font-weight: 500;
    color: #{$surface-on-variant};
  }

  .accordion-button-container {
    display: flex;
    flex-direction: row;
  }
  
  :global(.saving-preloader) {
    margin-right: 16px;
  }
</style>