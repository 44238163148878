<Page pageContent={false} id={id}>
    <PageContent>
        <Block style="max-width: 800px">
            <List inlineLabels noHairlinesMd>
                <ListInput label="Locatie id" type="select" outline bind:value={locationId} disabled={!$PERMISSION_MODIFY_PROJECTS}>
                    <option value=""></option>
                    {#each locationIds as fixedLocationId}
                        <option value={fixedLocationId}>{fixedLocationId}</option>
                    {/each}
                </ListInput>

                <ListItem>Scherm-link: 
                    {#if locationId}
                    <Link external target="_blank" href={screenUrl}>{screenUrl}</Link>
                    {:else}
                    Kies eerst een lokatie
                    {/if}
                </ListItem>

                {#each signallingRoutes as signallingRoute, index}
                    <ListInput outline label={`Route ${index + 1}`} type="select" bind:value={signallingRoutes[index].RouteId} placeholder={`Kies route ${index + 1}`}>
                        <option value=""></option>
                        {#each routes as route}
                            <option value={route.Id} selected={route.Id === signallingRoutes[index].RouteId}>{route.Name}</option>
                        {/each}
                    </ListInput>
                    <ListItem>
                        <input type="file" accept="image/png, image/jpeg" disabled={!$PERMISSION_MODIFY_PROJECTS} on:change={ev => onRouteImageChange(ev, index)}>
                    </ListItem>
                    {#if signallingRoute.Image}
                        <ListItem>
                            <img class="preview" src={makeImageSrc(signallingRoute.ContentType, signallingRoute.Image)} alt={`Route ${index + 1}`}>
                        </ListItem>
                    {/if}
                {/each}

                <ListItem>
                    <Button onClick={save} disabled={!validate(locationId, signallingRoutes)} fill>Opslaan</Button>
                </ListItem>
            </List>
        </Block>
    </PageContent>
</Page>

<script lang="ts">
    import { Block, Button, List, ListInput, ListItem, Link, Page, PageContent } from "framework7-svelte";
    import { PERMISSION_MODIFY_PROJECTS, PERMISSION_VIEW_INSIGHT } from "../utils/permissions";
    import { makeApiCall } from "../utils/api";
    import { notify } from "../utils/notify";
    import { IInsight, IProjectInfo, IRoute, ISignaling } from "../utils/apitypes";
    import { onMount } from "svelte";
    
    $: id = `project-${projectId}`;
    export let projectId: string;

    let locationId: string = "";
    let signallingRoutes: SignallingRoute[] = [];
    let routes: IRoute[] = [];
    const locationIds = Array.from({length: 20}, (_, i) => `L${(i+1).toString().padStart(4, '0')}`);

    onMount(async () => {
        await fetchRoutes();
        await loadData();
    })

    $: screenUrl = `${window.location.origin}/signaling/display-diversion/#${locationId}`

    export async function onShow() {
        await fetchRoutes();
        for (let i = 0; i < signallingRoutes.length; i++) {
            if (!routes.map(route => route.Id).includes(signallingRoutes[i].RouteId)) {
                signallingRoutes[i].RouteId = '';
                continue;
            }
            signallingRoutes[i] = signallingRoutes[i];
        }
    };

    async function fetchRoutes() {
        if(!PERMISSION_VIEW_INSIGHT) {
            notify('U heeft geen rechten om de routes te bekijken');
            return;
        }
        const data: IProjectInfo = {
            Id: projectId
        };
        const insightRaw = await makeApiCall('project/insight/get', data) as IInsight;
        insightRaw.Routes.sort((a,b) => a.Name.localeCompare(b.Name));
        routes = insightRaw.Routes;
    }

    async function onRouteImageChange(event, index) {
        const reader = new FileReader();
        reader.onload = () => {
            const data = reader.result as string;
            const image = data.split(',')[1];
            const contentType = data.split(';')[0].split(':')[1];
            signallingRoutes[index].Image = image;
            signallingRoutes[index].ContentType = mapContentType(contentType);
            console.log(signallingRoutes);
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    function validate(locationId: string, signallingRoutes: SignallingRoute[]): boolean {
        if (!locationId) {
            return false;
        }
        for (const route of signallingRoutes) {
            if (!route.RouteId) {
                return false;
            }
            if (!route.Image) {
                return false;
            }
        }
        return true;
    }

    async function save() {
        if(!PERMISSION_MODIFY_PROJECTS) {
            notify('U heeft geen rechten om de signalering te wijzigen');
            return;
        }
        const data = {
            ProjectId: projectId,
            LocationId: locationId,
            Routes: signallingRoutes
        };
        await makeApiCall('project/signaling/put', data);
        notify('Locatie opgeslagen');
    }

    async function loadData() {
        if(!PERMISSION_VIEW_INSIGHT) {
            notify('U heeft geen rechten om de signalering te zien');
            return;
        }
        const data = {
            Id: projectId
        };
        try {
            const result = await makeApiCall('project/signaling/get', data) as ISignaling;
            locationId = result.LocationId;
            signallingRoutes = result.Routes.map(route => {
                return {
                    RouteId: route.RouteId,
                    ContentType: route.ContentType,
                    Image: route.Image
                };
            });
        } catch (e) {
            notify('Er is een fout opgetreden bij het ophalen van de signalering');
        }
        
        while (signallingRoutes.length < 2) {
            signallingRoutes.push({ RouteId: '', ContentType: '', Image: '' });
        }
        signallingRoutes = signallingRoutes;
    }

    function makeImageSrc(contentType: string, base64String: string): string {
        return `data:${contentType};base64,` + base64String
    }

    function mapContentType(contentType: string) {
        switch (contentType) {
            case 'image/jpg':
            case 'image/jpeg': return 'image/jpg';

            case 'image/tiff':
            case 'image/tif': return 'image/tiff';
                
            case 'image/png': return 'image/png';

            default: throw new Error('Invalid content type: ' + contentType);
        }
    }

    class SignallingRoute {
        RouteId: string;
        ContentType: string;
        Image: string;
    }

</script>

<style lang="scss">
  @import '../assets/scss/colors';
  
  :global(img.preview) {
    max-width: 100%;
    max-height: 200px;
  }
</style>